@import url(./vars.less);

.App,
#root {
  // height: 100vh;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: system-ui;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.global-spin {
  position: fixed;
  top: 50%;
  left: 50%;
}

:root:root {
  --adm-color-primary: @themeColor;
  --adm-font-family: system-ui;
}

/* 滚动条 */

/*滚动条宽度*/

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Handle样式 */

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

/*当前窗口未激活的情况下*/

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.1);
}

/*hover到滚动条上*/

::-webkit-scrollbar-thumb:vertical:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

/*滚动条按下*/

::-webkit-scrollbar-thumb:vertical:active {
  background-color: rgba(0, 0, 0, 0.7);
}

input::-webkit-inner-spin-button {
  display: none !important;
}

/* 自动填充样式修改 */
input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #fff inset !important;
  text-fill-color: #000;
  -webkit-text-fill-color: #000;
  border-radius: 0;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.h100 {
  height: 100%;
}

.wd100 {
  width: 100%;
}

.mr-8 {
  margin-right: 8px;
}

.mr-16 {
  margin-right: 16px;
}

.pd-il-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.pb22 {
  padding-bottom: 22px;
}

.pb15 {
  padding-bottom: 15px;
}

.pd0 {
  padding-bottom: 0;
}

.pd10 {
  padding: 10px;
}

.fc-ccc {
  color: #ccc;
}

.fl {
  float: left;
  z-index: 8;
}

.fr {
  float: right;
  z-index: 8;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}

.bf-modal {
  z-index: 999 !important;
}

.list {
  .adm-list-item-content {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.image-upload-list {
  --cell-size: 65px;
}
