* {
  box-sizing: border-box;
}


body {
  margin: 0;
  font-family: system-ui;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: system-ui;
}

body.keyboard {
  height: calc(100% + 500px);
  /* add padding for keyboard */
}